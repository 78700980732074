import React from 'react'
import { graphql } from 'gatsby'
import WatPage from 'src/components/WatPage'

import 'antd/dist/antd.css' // or 'antd/dist/antd.less'
import 'bootstrap/dist/css/bootstrap.css'
import 'static/shared-style/normalize.css'
import 'static/shared-style/custom.scss'

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  return <WatPage wat={data.wat} product={data.product || []} watsList={data.watsList} />
}

export const pageQuery = graphql`
  query($path: String!) {
    wat: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        name
        name2
        group
        metaTitle
        metaDescription
        seoContent
        faq {
          question
          answer
        }
        useOwnProducts
        premiumSection {
          categoryName
          isShowSection
          products {
            best_seller
            selectedProduct
            id
            description
            colorTone
            category
            descMobile
            image
            imageS3
            isShow
            position
            price
            size
          }
        }
        bSection {
          categoryName
          isShowSection
          products {
            best_seller
            selectedProduct
            id
            description
            colorTone
            category
            descMobile
            image
            imageS3
            isShow
            position
            price
          }
        }
        aSection {
          categoryName
          isShowSection
          products {
            best_seller
            selectedProduct
            id
            description
            colorTone
            category
            descMobile
            image
            imageS3
            isShow
            position
            price
          }
        }
        fSection {
          categoryName
          isShowSection
          products {
            best_seller
            selectedProduct
            id
            description
            colorTone
            category
            descMobile
            image
            imageS3
            isShow
            position
            price
            size
          }
        }
      }
    }
    product: allMarkdownRemark(filter: { frontmatter: { layout: { eq: "product" } } }) {
      edges {
        node {
          id
          rawMarkdownBody
          frontmatter {
            best_seller
            category
            colorTone
            id
            image
            imageS3
            isShow
            price
            size
            slashPrice
            position
            descMobile
          }
        }
      }
    }
    watsList: allMarkdownRemark(filter: { frontmatter: { layout: { eq: "wat" } } }) {
      edges {
        node {
          frontmatter {
            name
            name2
            path
            group
            linkPosition
            landingPage {
              isShowOnLandingPage
              position
            }
          }
        }
      }
    }
  }
`
